import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Clients_3 from "../../../assets/svg/clients_all.svg";
import BoitierTotalImg from "../../../assets/svg/clients_all.svg";
import boitiervert from "../../../assets/svg/boitiervert.svg";
import boitiergris from "../../../assets/svg/boitiergris.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/custom.css";
import {
  API_URL,
  getApiConfig,
  getUserRoleId,
  token,
} from "../Config/apiConfig.js";
import { Card, CardBody, CardFooter, Row, Col } from "reactstrap";
import ShowClient from "../UserShow/clientShow/showClient";
import { OLD_API_URL, oldGetApiConfig, oldToken } from "../Config/oldApiConfig";
import OldBoitierAlert from "../BoitierAlert/oldBoitierAlert";

export default function HeaderDashboard(props) {
  const [columnWidth, setColumnWidth] = useState({});
  const loggedUser = parseInt(getUserRoleId());
  const nav = useNavigate();
  const roleId = getUserRoleId();
  const [totalCustomer, setTotalCustomer] = useState("");
  const [totalConcess, setTotalConcess] = useState("");
  const [totalReseller, setTotalReseller] = useState("");
  const [totalTech, setTotalTech] = useState("");
  const [activeInactiveBoitier, setActiveInactiveBoitier] = useState("");
  const [lastAlert, setLastAlert] = useState([]);
  const [oldActiveBoitier, setOldActiveBoitier] = useState("");
  const [oldTotaleBoitier, setOldTotaleBoitier] = useState("");
  const [nbClient, setNbClient] = useState("");
  const [oldTotalBoitier, setOldTotalBoitier] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (loggedUser === 1) {
        setColumnWidth(width < 767 ? { xs: 12, md: 6 } : { xs: 6, md: 6 });
      } else {
        setColumnWidth({ xs: 12, md: 12 });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [loggedUser]);

  useEffect(() => {
    const fetchData = async () => {
      let newApiRequests = [];
      let oldApiRequests = [];
      if (token) {
        newApiRequests = [
          axios.get(API_URL + "countTotalCustomer", getApiConfig()),
          axios.get(API_URL + "countTotalConcess", getApiConfig()),
          axios.get(API_URL + "countTotalReseller", getApiConfig()),
          axios.get(API_URL + "countTotalTech", getApiConfig()),
          axios.get(
            API_URL + "countTotalActiveInactiveBoitier",
            getApiConfig()
          ),
          axios.get(API_URL + "lastAlert", getApiConfig()),
        ];
      }
      if (oldToken) {
        oldApiRequests = [
          axios.get(OLD_API_URL + "boitier", oldGetApiConfig()),
          axios.get(OLD_API_URL + "dashboard", oldGetApiConfig()),
        ];
      }

      try {
        if (newApiRequests.length > 0) {
          const [totalCustomerRes, totalConcessRes, totalResellerRes, totalTechRes, activeInactiveBoitierRes, lastAlertRes] =
            await axios.all(newApiRequests);
          setTotalCustomer(totalCustomerRes.data.total);
          setTotalConcess(totalConcessRes.data.total);
          setTotalReseller(totalResellerRes.data.total);
          setTotalTech(totalTechRes.data.total);
          setActiveInactiveBoitier(activeInactiveBoitierRes.data);
          setLastAlert(
            lastAlertRes.data.filter((alert) => shouldDisplayAlert(alert))
          );
        }
        if (oldApiRequests.length > 0) {
          const [oldBoitierRes, oldDashboardRes] = await axios.all(
            oldApiRequests
          );
          setOldActiveBoitier(oldBoitierRes.data.meta.nb_actif);
          setOldTotaleBoitier(oldBoitierRes.data.meta.total);
          setNbClient(oldDashboardRes.data.nbClient);
          setOldTotalBoitier(oldDashboardRes.data.nbBoitier);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    return fetchData();
  }, []);

  const addParamToUrlForBoitier = (id) => {
    nav(`/showBoitier/${id}`);
  };

  const shouldDisplayAlert = (alert) => {
    const alertsVisibility = {
      "Température interne trop élevée": [1, 2, 3, 4, 5],
      "Alerte humidité interne trop élevée": [1, 2, 3, 4, 5],
      "Capteur de vibration": [1, 2, 3, 4, 5],
      "Alerte le boitier a été ouvert par dessus": [1, 2, 3, 4, 5],
      "Alerte le boitier a été ouvert ou est exposé à une lumière trop forte": [
        1, 2, 3, 4, 5
      ],
      "Perte de connexion du boitier": [1, 2, 3, 4, 5],
      "Le boîtier a redémarré": [1, 2, 3, 4, 5],
      "Bobine débranchée": [1, 2, 3, 4, 5]
    };

    return alertsVisibility[alert.title]?.includes(parseInt(roleId)) ?? true;
  };

  const getAlertColorClass = (title) => {
    const colorClassMap = {
      "Température interne trop élevée": "circle_form_orange",
      "Alerte humidité interne trop élevée": "circle_form_orange",
      "Capteur de vibration": "circle_form_orange",
      "Alerte le boitier a été ouvert par dessus": "circle_form_red",
      "Alerte le boitier a été ouvert ou est exposé à une lumière trop forte":
        "circle_form_red",
      "Perte de connexion du boitier": "circle_form_blue",
      "Le boîtier a redémarré": "circle_form_blue",
      "Bobine débranchée": "circle_form_orange"
    };

    return colorClassMap[title] || "circle_form_red";
  };

  const ActifsBoitier =
    activeInactiveBoitier && oldActiveBoitier ? (
      <h3>{activeInactiveBoitier.activeCount + oldActiveBoitier}</h3>
    ) : activeInactiveBoitier ? (
      <h3>{activeInactiveBoitier.activeCount}</h3>
    ) : oldActiveBoitier ? (
      <h3>{oldActiveBoitier}</h3>
    ) : null;

  const totalOldBoitierInactif = oldTotaleBoitier - oldActiveBoitier;

  const InactifsBoitier =
    totalOldBoitierInactif && activeInactiveBoitier ? (
      <h3>{activeInactiveBoitier.inactiveCount + totalOldBoitierInactif}</h3>
    ) : totalOldBoitierInactif ? (
      <h3>{totalOldBoitierInactif}</h3>
    ) : activeInactiveBoitier ? (
      <h3>{activeInactiveBoitier.inactiveCount}</h3>
    ) : null;

  return (
    <>
      <Col lg="12">
        <Row className="CenterAll">
          <div className="containertitle">
            <h2 className="titlePage">Tableau de bord</h2>
          </div>
        </Row>
        <Row className="CenterAll">
          <Card className="card-stats ">
            <Row>
              <Col xs={columnWidth.xs} md={columnWidth.md} className="pb-3 ">
                <Col
                  className={
                    "shadow mt-5 bg-white " + (loggedUser === 1 ? "" : "row")
                  }
                >
                  {(() => {
                    switch (Number(roleId)) {
                      case 1: // Admin
                        return (
                          <>
                            {/* Distributeur Section */}
                            <CardBody className="headerCenter">
                              <div className="DistributeurSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Distributeurs</h3>
                                <div>{totalConcess}</div>
                              </div>
                            </CardBody>

                            {/* Revendeur Section */}
                            <CardBody className="headerCenter">
                              <div className="revendeurSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="Revendeurs"
                                />
                                <h3>Revendeurs</h3>
                                <div>{totalReseller}</div>
                              </div>
                            </CardBody>


                            {/* Technicien Section */}
                            <CardBody className="headerCenter">
                              <div className="technicienSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="Techniciens"
                                />
                                <h3>Techniciens</h3>
                                <div>{totalTech}</div>
                              </div>
                            </CardBody>

                            {/* Client Section */}
                            <CardBody className="headerCenter">
                              <div className="clientSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Clients</h3>
                                <div>{totalCustomer}</div>
                              </div>
                            </CardBody>
                            {/* Boitier Section */}
                            <CardBody className="headerCenter">
                              <div className="boitierSection">
                                <img
                                  src={boitiervert}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Boîtiers</h3>
                                <div>{ActifsBoitier}</div>
                              </div>
                            </CardBody>

                          </>
                        );
                      case 2: // Distributeur
                        return (
                          <>



                            {/* Revendeur Section */}
                            <CardBody className="headerCenter">
                              <div className="revendeurSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="Revendeurs"
                                />
                                <h3>Revendeurs</h3>
                                <div>{totalReseller}</div>
                              </div>
                            </CardBody>

                            {/* Client Section */}
                            <CardBody className="headerCenter">
                              <div className="clientSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="Clients"
                                />
                                <h3>Clients</h3>
                                <div>{totalCustomer}</div>
                              </div>
                            </CardBody>
                            {/* Boitier Section */}
                            <CardBody className="headerCenter">
                              <div className="boitierSection">
                                <img
                                  src={boitiervert}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Boîtiers</h3>
                                <div>{ActifsBoitier}</div>
                              </div>
                            </CardBody>

                          </>
                        );
                      case 3: // Revendeur
                        return (
                          <>
                            {/* Technicien Section */}
                            <CardBody className="headerCenter">
                              <div className="technicienSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="Techniciens"
                                />
                                <h3>Techniciens</h3>
                                <div>{totalTech}</div>
                              </div>
                            </CardBody>


                            {/* Client Section */}
                            <CardBody className="headerCenter">
                              <div className="clientSection">
                                <img
                                  src={Number(roleId) !== 5 ? Clients_3 : BoitierTotalImg}
                                  className="userImage minHeight80 resizedImage"
                                  alt="Clients"
                                />
                                <h3>Clients</h3>
                                <div>{totalCustomer}</div>
                              </div>
                            </CardBody>
                            {/* Boitier Section */}
                            <CardBody className="headerCenter">
                              <div className="boitierSection">
                                <img
                                  src={boitiervert}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Boîtiers</h3>
                                <div>{ActifsBoitier}</div>
                              </div>
                            </CardBody>

                          </>
                        );
                      case 4: // Technicien
                        return (
                          <>
                            {/* Boitier Section */}
                            <CardBody className="headerCenter">
                              <div className="boitierSection">
                                <img
                                  src={boitiervert}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Boîtiers</h3>
                                <div>{ActifsBoitier}</div>
                              </div>
                            </CardBody>
                          </>
                        );
                      case 5: // Client
                        return (
                          <>
                            {/* Boitier Section */}
                            <CardBody className="headerCenter">
                              <div className="boitierSection">
                                <img
                                  src={boitiervert}
                                  className="userImage minHeight80 resizedImage"
                                  alt="React Logo"
                                />
                                <h3>Boîtiers</h3>
                                <div>{ActifsBoitier}</div>
                              </div>
                            </CardBody>
                          </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </Col>
              </Col>
              {(loggedUser === 1 || loggedUser === 5) ? ( //garder ce filtre
                <Col xs={columnWidth.xs} md={columnWidth.md}>
                  <Card className="mt-5">
                    <CardBody>
                      {lastAlert?.map((item, index) => (
                        <Card
                          key={index}
                          className="shadow mb-5 bg-white rounded card-hover"
                          onClick={() =>
                            addParamToUrlForBoitier(item.boitier_id)
                          }
                        >
                          <CardBody>
                            <div className="ml-5 mr-5 d-flex">
                              <div
                                className={`${getAlertColorClass(
                                  item.title
                                )} mr-4`}
                              ></div>
                              <div className="d-flex flex-column">
                                <div>BOITIER N° {item.boitier_id}</div>
                                {item.unite === "Booléen" ? (
                                  <div>{item.title}</div>
                                ) : (
                                  <div>
                                    {item.title}: {item.value} {item.unite}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="float-right">
                              <div>{item.datetime}</div>
                            </div>
                          </CardBody>
                        </Card>
                      ))}
                    </CardBody>
                  </Card>
                </Col>
              ) : null}
            </Row>
          </Card>
        </Row>
      </Col>
    </>
  );
}
