import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-table-v6/react-table.css";
import ReactTable from "react-table-v6";
import { Card, CardBody, Row, Col } from "reactstrap";
import { API_URL, getApiConfig, token } from "../Config/apiConfig.js";
import { useNavigate } from "react-router-dom";
import customFilterMethod from "helpers/customFilterMethod.js";
import {  OLD_API_URL,oldGetApiConfig,oldToken, } from "../Config/oldApiConfig.js";

export default function UserListTab({ role_id }) {
  const nav = useNavigate();

  const [userListRequest, setUserListRequest] = useState("");
  const [userList, setUserList] = useState([]);
  const [userListRequestOld, setUserListRequestOld] = useState("");
  const [userListOld, setUserListOld] = useState([]);
  useEffect(() => {
    setUserListRequest(null);
    setUserListRequestOld(null);
    console.log("role_id", role_id);

    if (role_id == 2) {
      setUserListRequest("showConcessList");
      setUserListRequestOld("concessionnaire");
    } else if (role_id == 3) {
      setUserListRequest("showResellerList");
      setUserListRequestOld("revendeur");
    } else if (role_id == 4) {
      setUserListRequest("showTechList");      
      setUserListRequestOld("technicien");
    } else {
      setUserListRequest("showCustomerList");
      setUserListRequestOld("client");
    }
  }, [role_id]);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(API_URL + userListRequest, getApiConfig());
          setUserList(response.data);
          console.log("userList", response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      if (oldToken) {
        try {
          const response = await axios.get(OLD_API_URL + userListRequestOld, oldGetApiConfig());
          setUserListOld(response.data);
          console.log("userListold", response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    if (userListRequest) {
      fetchData();
    }
  }, [userListRequest]);

  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  const formatAddress = (adresse) => {
    if (!adresse) return " ";
    return `${adresse.locality}, ${adresse.country}`;
  };

  const formatName = (d) => {
    if (d.name && d.firstname) {
      return `${d.name} ${d.firstname}`;
    } else if (d.contact && d.contact.nom && d.contact.prenom) {
      return `${d.contact.nom}, ${d.contact.prenom}`;
    }
    return " ";
  };

  const findUserByEmail = (email) => {
    console.log("Recherche email:", email);
    
    const oldUser = userListOld.data?.find((user) => 
      user.email === email || user.contact?.loginEmail === email
    );
    
    if (oldUser) {
      console.log("Utilisateur trouvé:", oldUser);
      localStorage.setItem("oldUserId", oldUser.id);
    } else {
      console.log("Aucun utilisateur trouvé pour cet email");
      localStorage.removeItem("oldUserId");
    }
    
    return oldUser;
  };
  function renderTelephoneCell(d) {
    return (
      <div>
        {d && d.phone1 && (
          <span>
            {d.phone1}
            <br />
          </span>
        )}
        {d && d.contact && d.contact.tel1 && (
          <span>
            {d.contact.tel1}
            <br />
          </span>
        )}
        {d && d.contact && d.contact.tel2 && (
          <span>
            {d.contact.tel2}
            <br />
          </span>
        )}
      </div>
    );
  }

  const formatRevendeur = (d) => {
    if (d.parentName || d.parentFirstname) {
      return `${d.parentName || ""} ${d.parentFirstname || ""}`.trim();
    } else {
      return d.revendeur?.nom ? d.revendeur?.nom : "";
    }
  };

  const getColumnsByRole = (role_id) => {
    switch (Number(role_id)) {
      case 2: // for concess
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>DISTRIBUTEUR</div>
            ),
            accessor: (d) => d.company || d.nom,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>NOMBRE DE REVENDEUR</div>
            ),
            accessor: (d) => d.count || d.nbrrevendeur || 0,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
            accessor: (d) => d.state || d.statut,
            Cell: ({ value }) => (
              <div
                style={{
                  color: value === true || value === 1 ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {value === true || value === 1 ? "actif" : "inactif"}
              </div>
            ),
          },
        ];
      case 3: // for Reseller
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>REVENDEUR</div>,
            accessor: (d) => d.company || d.nom,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>NOMBRE DE CLIENT</div>
            ),
            accessor: (d) => d.count || d.nbreclient || 0,
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>NOMBRE DE BOITIER</div>
            ),
            accessor: (d) => d.nbrboitier || " ",
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
            accessor: (d) => d.state || d.statut,
            Cell: ({ value }) => (
              <div
                style={{
                  color: value === true || value === 1 ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {value === true || value === 1 ? "actif" : "inactif"}
              </div>
            ),
          },
        ];
      case 4: // for Tech
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>TECHNICIEN</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>TELEPHONE</div>,
            accessor: (d) => renderTelephoneCell(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>
                NOMBRE D'INTERVENTIONS REALISES
              </div>
            ),
            accessor: (d) => "TODO",
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
            accessor: (d) => d.state || d.statut,
            Cell: ({ value }) => (
              <div
                style={{
                  color: value === true || value === 1 ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {value === true || value === 1 ? "actif" : "inactif"}
              </div>
            ),
          },
        ];
      default: // for Customer
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID (TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CLIENT</div>,
            accessor: (d) => d.company || d.nom,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>REVENDEUR</div>,
            accessor: (d) => formatRevendeur(d),
          },
        ];
    }
  };

  // Later in your code:
  const columns = getColumnsByRole(role_id).map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  const onRowClick = (_, rowInfo) => {
    return {
      onClick: () => {
        console.log(rowInfo.original.id, "rowInfo");
        const roleIdNumber = Number(role_id);
        console.log(roleIdNumber, "role_id");
        console.log(findUserByEmail(rowInfo.original.email), "findUserByEmail");
        switch (roleIdNumber) {
          case 2:
            nav(`/ShowDistrib/${rowInfo.original.id}`);
            break;
          case 3:
            nav(`/showResel/${rowInfo.original.id}`);
            break;
          case 4:
            nav(`/showTech/${rowInfo.original.id}`);
            break;
          case 5:
            nav(`/ShowUser/${rowInfo.original.id}`);
            break;
          default:
            return;
        }
      },
    };
  };
  

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center">
              <Card>
                <CardBody>
                  {userList ? (
                    <ReactTable
                      filterable
                      getTrProps={onRowClick}
                      value={userList?.id}
                      data={userList}
                      defaultFilterMethod={customFilterMethod}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                      // onPageChange={(pageIndex) => {
                      //   // A supprimer, ou modifier, ce code ne fonctionne pas mais est sensé demander de fetch de nouvelles données
                      //   const page = pageIndex + 1;
                      //   axios
                      //     .get("GOOD URL", {
                      //       params: {
                      //         nb_par_page: 10,
                      //         page: page,
                      //       },
                      //     })
                      //     .then((response) => {
                      //       // update your state with the new data
                      //     })
                      //     .catch((error) => {
                      //       console.error("There was an error!", error);
                      //     });
                      // }} // si aucun bug supprimer
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
}