import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom"; // Importer Link
import { Button } from "reactstrap";
import { FormGroup } from "reactstrap";
import { Form } from "reactstrap";
import { Input } from "reactstrap";
import { Row } from "reactstrap";
import LogoMain from "assets/img/logoacquagestblanc-small.png";
import "./auth.css";

let newToken = "";
let oldToken = "";

export default function Login({ setUserRole }) {
  const nav = useNavigate();
  const API_URL = "https://acquaback.shenron.dev/api/";
  let CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const [formVisible, setFormVisible] = useState(false);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const loginError = document.getElementById("login-error");
  const [resetError, setResetError] = useState(""); // Utiliser useState pour le message d'erreur
  const [resetSuccess, setResetSuccess] = useState(""); // Utiliser useState pour le message de succès
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // État pour désactiver le bouton

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(
        API_URL + "login",
        {
          email,
          password,
        },
        CONFIG_
      )
      .then( function (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_params_id", response.data.user.id);
        localStorage.setItem("user_role_id", response.data.user.role_id);
        localStorage.setItem("loggedUser", JSON.stringify(response.data.user));

        newToken = response.data.token;

        if (response.data.user.password_changed === 1) {
          const userRoleId = localStorage.getItem("user_role_id");
          setUserRole(parseInt(userRoleId));

          nav("/Dashboard");
        } else {
          //nav("/Updatepassword")
          nav("/resetPassword"); // A dégager il faudra rediriger vers la page de changement de mot de passe
        }
      })
      .catch(function (error) {
        if (error.message === "Request failed with status code 401") {
          loginError.textContent = "Email ou mot de passe incorrect";
        }
      });
      fetch("https://acquagest-api.acquaprocess.eu/api/login", {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    email,
    password,
    device_name: "react-spa",
  }),
})
  .then((response) => {
    if (!response.ok) {
      // Si la réponse n'est pas 2xx, lève une erreur avec le statut
      throw new Error(`Erreur de requête: ${response.status}`);
    }
    return response.json(); // Parse la réponse JSON
  })
  .then((data) => {
    const oldToken = data.token;
    localStorage.setItem("oldtoken", oldToken); // Stocke le token dans le localStorage
    console.log("Old token attribué:", oldToken);
        nav("/Dashboard");

  })
  .catch((error) => {
    console.error("Erreur lors de la requête:", error.message);
  });

    // Redirection vers le tableau de bord
    // } catch (error) {
    //     // Gestion des erreurs pour la première requête
    //     if (error.response) {
    //         console.error("Erreur lors de la connexion:", error.response.data); // Afficher les détails de l'erreur
    //         if (error.response.status === 401) {
    //             document.getElementById("login-error").textContent = "Email ou mot de passe incorrect";
    //         }
    //     } else {
    //         console.error("Erreur de connexion:", error.message);
    //     }

    //     // Gestion des erreurs pour la récupération de l'ancien token
    //     console.error("Erreur lors de la tentative de récupération du token:", error.message);
    //     console.error("Old token n'a pas été attribué");
    // }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setResetError(""); // Réinitialiser le message d'erreur
    setResetSuccess(""); // Réinitialiser le message de succès

    if (!isButtonDisabled) {
      // Vérifier si le bouton n'est pas déjà désactivé
      setIsButtonDisabled(true); // Désactiver le bouton

      axios
        .post(
          API_URL + "forgot-password",
          {
            email,
          },
          CONFIG_
        )
        .then(function (response) {
          if (
            response.data.message === "Reset code has been sent to your email!"
          ) {
            setResetSuccess(
              "Le lien de réinitialisation a bien été envoyé, vérifiez vos spam."
            );
          }
        })
        .catch(function (error) {
          if (error.message === "Request failed with status code 404") {
            setResetError("Cet email n'est pas reconnu.");
          }
        })
        .finally(() => {
          setIsButtonDisabled(false); 
        });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setResetError(""); // Réinitialiser le message d'erreur
    setResetSuccess(""); // Réinitialiser le message de succès
  };

  return (
    <div className="height-login">
      <Row className="center-login">
        <div className="background-login">
          {!formVisible ? (
            <Form className="container-login" onSubmit={handleSubmit}>
              <img className="logo-login" src={LogoMain} alt="logo-login"></img>
              <div className="label-passwordUp">
                Connectez vous à l'outil acquagest :
              </div>
              <FormGroup>
                <Input
                  className=" input-login"
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  onChange={handleEmailChange} // Utiliser handleEmailChange
                />
                <Input
                  className=" input-login"
                  type="password"
                  placeholder="Mot de passe"
                  name="psw"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <div id="login-error" className="confirm-error"></div>
              <Button className="" color="primary" type="submit">
                Se connecter
              </Button>
              <div
                className="label-passwordUp clickable-link"
                onClick={handleToggleForm}
              >
                Mot de passe oublié ?
              </div>
            </Form>
          ) : (
            <Form className="container-login" onSubmit={handleForgotPassword}>
              <img className="logo-login" src={LogoMain} alt="logo-login"></img>
              <div className="label-passwordUp">
                Réinitialisez votre mot de passe :
              </div>
              <FormGroup>
                <Input
                  className="input-login"
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  onChange={handleEmailChange} // Utiliser handleEmailChange
                />
              </FormGroup>
              <div id="reset-success" className="confirm-success">
                {resetSuccess && (
                  <>
                    {resetSuccess}{" "}
                    <Link to="/password/reset">
                      Cliquez ici pour réinitialiser
                    </Link>
                  </>
                )}
              </div>
              <div id="reset-error" className="confirm-error">
                {resetError}
              </div>
              <Button
                className=""
                color="primary"
                type="submit"
                disabled={isButtonDisabled} // Désactiver le bouton si isButtonDisabled est true
              >
                Réinitialiser
              </Button>
              <div
                className="label-passwordUp clickable-link"
                onClick={handleToggleForm}
              >
                Retour à la connexion
              </div>
            </Form>
          )}
        </div>
      </Row>
    </div>
  );
}

export { newToken, oldToken };
