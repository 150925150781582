import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

// Auth
import Auth from "layouts/Auth/Auth.js";
/*import UpdatePassword from "layouts/Auth/UpdatePassword.js";*/
import ResetPassword from "layouts/Auth/ResetPassword.js";

//Dashboard
import Dashboard from "views/composant/Dashboard/Dashboard.js";

//BoitierList
import Boitiers from "views/composant/BoitierList/boitiers.js";

//UserManagement
import UserAdd from "views/composant/UserAdd/userAdd.js";
import UserList from "views/composant/UserList/userList.js";
import ShowClient from "views/composant/UserShow/clientShow/showClient.js";
import ShowTech from "views/composant/UserShow/technicienShow/showTechnicien.js";
import ShowResel from "views/composant/UserShow/revendeurShow/showRevendeur.js";
import ShowDistrib from "views/composant/UserShow/distributeurShow/showDistributeur.js";
import ShowProfil from "views/composant/UserShow/profilShow/showProfil.js";
//boitier
import ShowBoitier from "views/composant/BoitierShow/showBoitier.js";
import BoitierAlert from "views/composant/BoitierAlert/boitierAlert.js";
import BoitierChart from "views/composant/BoitierChart/boitierChart.js";
//old boitier
import OldBoitierAlert from "views/composant/BoitierAlert/oldBoitierAlert";

//reglages-admin
import Reglages from "views/composant/Admin/reglages/reglages.js";

// import UserProfile from "views/composant///.js";
import ClientReglages from "views/composant/Client/userSettings/ClientReglages.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminInfo from "views/composant/Admin/adminInfo/adminInfo.js";
import AdminLogin from "views/composant/Maintenance/AdminLogin";
import MaintenancePage from "views/composant/Maintenance/MaintenancePage";
// Composant pour afficher la navbar admin si nécessaire
function AdminNavbarWrapper() {
  const location = useLocation();
  /*const isUpdatePasswordPage = location.pathname.includes("/UpdatePassword");*/
  const isResetPasswordPage = location.pathname.includes("/password/reset");

  const shouldRenderAdminNavbar =
    location.pathname !== "/" && /*!isUpdatePasswordPage &&*/ !isResetPasswordPage;

  return shouldRenderAdminNavbar ? (
    <AdminNavbar location={window.location} />
  ) : null;
}

// Filtrage des utilisateurs pour afficher les détails correspondants
const ShowUserFilter = () => {
  const { id } = useParams();
  const outerString = localStorage.getItem("loggedUser");
  const outerObject = JSON.parse(outerString);
  const userId = outerObject.id;

  if (id === userId) {
    return <ShowClient />;
  } else {
    return <Navigate to={`/`} />;
  }
};

// Ce composant permet de forcer un rechargement du navigateur et le nettoyage des composants à chaque changement de route
const RouteWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      window.location.reload(); // Recharge la page à chaque changement de route
    };

    return () => {
      handleRouteChange(); // Nettoie les composants avant chaque changement de route
    };
  }, [location]);

  return <>{children}</>;
};

// Récupère le rôle de l'utilisateur à partir du localStorage
function getUserRoleFromLocalStorage() {
  const userRoleId = localStorage.getItem("user_role_id");
  return userRoleId ? parseInt(userRoleId) : null;
}
function getUserParamsIdFromLocalStorage() {
  const userParamsId = localStorage.getItem("user_params_id");
  return userParamsId ? parseInt(userParamsId) : null;
}

function App() {
  const [userRole, setUserRole] = useState(getUserRoleFromLocalStorage());
  const userParamsId = getUserParamsIdFromLocalStorage();
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  console.log('isMaintenanceMode', isMaintenanceMode);

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/admin-login" element={<AdminLogin setUserRole={setUserRole} />} />
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/password/reset" element={<ResetPassword />} />
        <Route path="/" element={<Auth setUserRole={setUserRole} />} />
        <Route
          path="/*"
          element={
            <RouteWrapper>
              <AdminNavbarWrapper />
              <Routes>
                {/* Routes admin */}
                {userRole === 1 && (
                  <>
                    <Route path="/AdminInfo" element={<AdminInfo />} />
                    <Route path="/UserAdd/:id" element={<UserAdd />} />
                    <Route path="/UserList/:id" element={<UserList />} />
                    <Route path="/reglage" element={<Reglages />} />
                    <Route path="/ClientReglages" element={<ClientReglages />} />
                    <Route path="/ShowUser/:id" element={<ShowClient />} />
                    <Route path="/ShowTech/:id" element={<ShowTech />} />
                    <Route path="/ShowResel/:id" element={<ShowResel />} />
                    <Route path="/ShowDistrib/:id" element={<ShowDistrib />} />
                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route path="/boitierAlert/:id" element={<BoitierAlert />} />
                    <Route path="/OldBoitierAlert/:id" element={<OldBoitierAlert />} />
                    <Route path="/boitierChart/:id" element={<BoitierChart />} />
                  </>
                )}

                {/* Routes userRole 2 */}
                {userRole === 2 && (
                  <>
                    <Route path={`/UserList/:id`} element={<UserList />} />
                    <Route path={`/UserAdd/:id`} element={<UserAdd />} />
                    <Route path={"/ShowTech/:id"} element={<ShowTech />} />
                    <Route path={"/ShowResel/:id"} element={<ShowResel />} />
                    <Route path={"/ShowUser/:id"} element={<ShowClient />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route path="/boitierAlert/:id" element={<BoitierAlert />} />
                    <Route path="/OldBoitierAlert/:id" element={<OldBoitierAlert />} />
                    <Route path="/boitierChart/:id" element={<BoitierChart />} />
                  </>
                )}

                {/* Routes userRole 3 */}
                {userRole === 3 && (
                  <>
                    <Route path={`/UserList/:id`} element={<UserList />} />
                    <Route path={`/UserAdd/:id`} element={<UserAdd />} />
                    <Route path={"/ShowTech/:id"} element={<ShowTech />} />
                    <Route path={"/ShowUser/:id"} element={<ShowClient />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route path="/boitierAlert/:id" element={<BoitierAlert />} />
                    <Route path="/OldBoitierAlert/:id" element={<OldBoitierAlert />} />
                    <Route path="/boitierChart/:id" element={<BoitierChart />} />
                  </>
                )}

                {/* Routes userRole 4 */}
                {userRole === 4 && (
                  <>
                    <Route path={`/UserList/:id`} element={<UserList />} />
                    <Route path={"/ShowUser/:id"} element={<ShowClient />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route path="/boitierAlert/:id" element={<BoitierAlert />} />
                    <Route path="/OldBoitierAlert/:id" element={<OldBoitierAlert />} />
                    <Route path="/boitierChart/:id" element={<BoitierChart />} />
                  </>
                )}

                {/* Routes userRole 5 */}
                {userRole === 5 && (
                  <>
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route path="/boitierAlert/:id" element={<BoitierAlert />} />
                    <Route path="/OldBoitierAlert/:id" element={<OldBoitierAlert />} />
                    <Route path="/boitierChart/:id" element={<BoitierChart />} />
                    <Route path="/ShowProfil/" element={<ShowProfil />} />
                  </>
                )}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </RouteWrapper>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
